<template>
  <div v-if="!readonly" class="p-d-flex Creator">
    <CreatorEdit ref="creatorEdit" :create-view="true"></CreatorEdit>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CreatorEdit from "./Creator/CreatorEdit.vue";
import {
  dispatchListCrews,
  dispatchListManagerRoles,
} from "@/store/main/actions";
import { commitUnsetCreator } from "@/store/creator/mutations";
import { readIsManager } from "@/store/auth/getters";

@Component({
  components: {
    CreatorEdit,
  },
})
export default class Creator extends Vue {
  public async mounted() {
    window["analytics"]["page"]("/manage/creators/create");
    await commitUnsetCreator(this.$store);
    await dispatchListCrews(this.$store);
    await dispatchListManagerRoles(this.$store);
  }

  get isManager() {
    return readIsManager(this.$store);
  }

  get readonly() {
    return !this.isManager;
  }

  beforeRouteLeave(to, from, next) {
    if (this.$refs.creatorEdit["edited"]()) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }

  public beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  }

  preventNav(event) {
    if (!this.$refs.creatorEdit["edited"]()) {
      return;
    }
    event.preventDefault();
    event.returnValue = "";
  }
}
</script>

<style>
.Creator {
  background-color: var(--white);
  height: 100%;
  overflow: hidden;
  position: relative;
}

#right-edit-block {
  height: 100%;
  width: 50%;
}
</style>
